import React, {useRef, useEffect} from "react"
import { Link } from "gatsby"

let injectedScript = false

export const onInitialClientRender = () => {
  console.log('onInitialClientRender');
    function addJS(jsCode) {
        var s = document.createElement(`script`);
        s.type = `text/javascript`;
        s.innerText = jsCode;
        document.getElementsByTagName(`head`)[0].appendChild(s);
    }
    if (!injectedScript) {
        addJS(`
    var appInsights = window.appInsights || function (a) {
        function b(a) { c[a] = function () { var b = arguments; c.queue.push(function () { c[a].apply(c, b) }) } } var c = { config: a }, d = document, e = window; setTimeout(function () { var b = d.createElement("script"); b.src = a.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js", d.getElementsByTagName("script")[0].parentNode.appendChild(b) }); try { c.cookie = d.cookie } catch (a) { } c.queue = []; for (var f = ["Event", "Exception", "Metric", "PageView", "Trace", "Dependency"]; f.length;)b("track" + f.pop()); if (b("setAuthenticatedUserContext"), b("clearAuthenticatedUserContext"), b("startTrackEvent"), b("stopTrackEvent"), b("startTrackPage"), b("stopTrackPage"), b("flush"), !a.disableExceptionTracking) { f = "onerror", b("_" + f); var g = e[f]; e[f] = function (a, b, d, e, h) { var i = g && g(a, b, d, e, h); return !0 !== i && c["_" + f](a, b, d, e, h), i } } return c
    }({
        instrumentationKey: "8c16403a-ade4-4793-8d23-2e2ca1d2f470"
    });

    window.appInsights = appInsights, appInsights.queue && 0 === appInsights.queue.length && appInsights.trackPageView();
      `);
        injectedScript = true;
    }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    window.appInsights.trackPageView();
}

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  const scrolled = useRef(null)

  useEffect(() => {
      const headID = document.getElementsByTagName('head')[0]

      document.addEventListener('scroll', function (e) {
          if (!scrolled.current) {
              scrolled.current = true

              // Google Ads
              const gaScript = document.createElement('script')
              gaScript.async = true
              gaScript.crossorigin = 'anonymous'
              gaScript['data-ad-client'] = 'ca-pub-1335514337857818'
              gaScript.src =
                  'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
              gaScript.type = 'text/javascript'
              headID.appendChild(gaScript)
          }
      })
  }, []);  

  if (isRootPath) {
    header = (
      <table>
        <tbody>
          <tr>
            <td>
              <h1 className="main-heading">
                <Link to="/">{title}</Link>
              </h1>
            </td>
            <td>
              <h2 className="link-heading">
                <Link to="/profile">Me</Link>
              </h2>
            </td>
            <td>
              <h2 className="link-heading">
                <Link to="/mywork">My Work</Link>
              </h2>
            </td>
          </tr>
        </tbody>
      </table>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <header className="global-header">{header}</header>
        <main>{children}</main>
        <footer>
          © Paul Michaels {new Date().getFullYear()}
        </footer>
      </div>

    
  )
}

export default Layout
